/**
 * An element which displays a priority triangle for vulnerabilities.
 */
angular.module('quay').directive('vulnerabilityPriorityView', function () {
  var directiveDefinitionObject = {
    priority: 0,
    templateUrl: '/static/directives/vulnerability-priority-view.html',
    replace: false,
    transclude: true,
    restrict: 'C',
    scope: {
      'priority': '=priority',
      'hideIcon': '@hideIcon'
    },
    controller: function($scope, $element, VulnerabilityService) {
      $scope.color = '';

      $scope.$watch('priority', function(priority) {
        if (priority) {
          $scope.color = VulnerabilityService.LEVELS[priority]['color'];
        }
      });
    }
  };
  return directiveDefinitionObject;
});